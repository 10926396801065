/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~swiper/scss';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/navigation';
@import '~@ionic/angular/css/ionic-swiper';


ion-modal.datetime-modal {
    --background: rgba(44, 39, 45, 0.2);
    &::part(content) {
        backdrop-filter: blur(6px);
    }
}

ion-modal.datetime-modal .datetime-wrapper {
    --background: transparent;
    --padding-top: 20vh;
    --padding-start: 20px;
    --padding-end: 20px;

    ion-datetime {
        padding: 4px;
        border-radius: 8px;
    }
}
