/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #22305d;
  --ion-color-primary-rgb: 34, 48, 93;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1e2a52;
  --ion-color-primary-tint: #38456d;
  --ion-color-secondary: #438acb;
  --ion-color-secondary-rgb: 67, 138, 203;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #3b79b3;
  --ion-color-secondary-tint: #5696d0;
  --ion-color-tertiary: #c8c5d8;
  --ion-color-tertiary-rgb: 200, 197, 216;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #b0adbe;
  --ion-color-tertiary-tint: #cecbdc;
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;
  --ion-background-color: black;
}

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark);
  --ion-color-base-rgb: var(--ion-color-dark-rgb);
  --ion-color-contrast: var(--ion-color-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-shade);
  --ion-color-tint: var(--ion-color-dark-tint);
}

ion-popover,
ion-card,
ion-alert {
  --ion-background-color: white !important;
}

ion-modal {
  --ion-background-color: var(--ion-color-primary) !important;
}